import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils'; // Định nghĩa data type của component [props] bằng Typescript

var UserStatusComponentProps = function UserStatusComponentProps() {
  _classCallCheck(this, UserStatusComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var UserStatusComponentData = function UserStatusComponentData() {
  _classCallCheck(this, UserStatusComponentData);
};

export default defineComponent({
  name: 'UserStatusComponent',
  props: {
    status: {
      type: String,
      default: null,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      currentUserStatus: null
    });

    var getStatusData = function getStatusData(status) {
      switch (status) {
        case 'INIT':
          data.currentUserStatus = {
            text: 'Chưa xác thực',
            class: ''
          };
          break;

        case 'WAITING':
          data.currentUserStatus = {
            text: 'Chờ xác thực',
            class: 'user-status-component--waiting'
          };
          break;

        case 'IDENTIFIED':
          data.currentUserStatus = {
            text: 'Đã xác thực',
            class: 'user-status-component--identified'
          };
          break;

        case 'REJECTED':
          data.currentUserStatus = {
            text: 'Bị từ chối',
            class: ''
          };
          break;

        case 'SIGNED_CONTRACT':
          data.currentUserStatus = {
            text: 'Đã ký hợp đồng',
            class: 'user-status-component--signed-contract'
          };
          break;

        default:
          data.currentUserStatus = {
            text: '',
            class: ''
          };
          break;
      }
    };

    watch(function () {
      return props.status;
    }, function (newValue, oldValue) {
      return getStatusData(newValue);
    });
    onMounted(function () {
      return getStatusData(props.status);
    });
    return {
      // Component reactive data
      data: data
    };
  }
});