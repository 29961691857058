import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/icons/status_icon_signed_contract.png';
import _imports_1 from '@/assets/icons/status_icon_check.png';
import _imports_2 from '@/assets/icons/status_icon_waiting.png';
import _imports_3 from '@/assets/icons/status_icon_uncheck.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-22847fec"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "user-status-component__icon",
  src: _imports_0
};
var _hoisted_2 = {
  key: 1,
  class: "user-status-component__icon",
  src: _imports_1
};
var _hoisted_3 = {
  key: 2,
  class: "user-status-component__icon",
  src: _imports_2
};
var _hoisted_4 = {
  key: 3,
  class: "user-status-component__icon",
  src: _imports_3
};
var _hoisted_5 = {
  class: "user-status-component__text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-status-component", _ctx.data.currentUserStatus ? _ctx.data.currentUserStatus.class : ''])
  }, [_ctx.status === 'SIGNED_CONTRACT' ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _ctx.status === 'IDENTIFIED' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _ctx.status === 'WAITING' ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4)), _createElementVNode("span", _hoisted_5, _toDisplayString((_a = _ctx.data.currentUserStatus) === null || _a === void 0 ? void 0 : _a.text), 1
  /* TEXT */
  )], 2
  /* CLASS */
  );
}